import * as React from 'react';

import { classes } from './Title.st.css';
import { stripMarkTags } from '../stripMarkTags';

interface ITitleProps {
  onClick?(e: React.MouseEvent<HTMLElement>): void;
  title: string;
  url?: string;
  role?: string;
}

interface IDataHookAttribute {
  'data-hook'?: string;
}

export const Title: React.FunctionComponent<ITitleProps> = props => {
  const commonProps: React.HTMLAttributes<HTMLAnchorElement | HTMLSpanElement> &
    IDataHookAttribute = {
    title: stripMarkTags(props.title),
    role: props.role,
    'data-hook': 'item-title',
    onClick: props.onClick,
    dangerouslySetInnerHTML: { __html: props.title },
  };

  if (props.url) {
    return <a {...commonProps} className={classes.root} href={props.url} />;
  }

  return <span {...commonProps} className={classes.root} />;
};
