import * as React from 'react';
import { SearchBox } from '../SearchBox';
import { ISearchInputProps } from './SearchInput.types';

export class SearchInput extends React.Component<ISearchInputProps> {
  render() {
    return (
      <div data-hook="search-form">
        <SearchBox
          value={this.props.value}
          placeholder={this.props.placeholder}
          clearLabel={this.props.clearLabel}
          onChange={this.props.onChange}
          onClear={this.props.onClear}
          onSubmit={this.props.onSubmit}
        />
      </div>
    );
  }
}
