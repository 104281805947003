import * as React from 'react';

import { Dropdown } from 'wix-ui-tpa/Dropdown';

import { ISearchSortProps } from './SortControl.types';
import { classes } from './SortControl.st.css';

const SortControlComponent: React.FunctionComponent<ISearchSortProps> = ({
  onSortChange,
  selectedSortOption,
  selectLabel,
  showSort,
  sortOptions,
}) => {
  return showSort ? (
    <div className={classes.withSort}>
      <div className={classes.resultsWithSort}>
        <Dropdown
          data-hook="search-sort-dropdown"
          onChange={({ id }) => {
            onSortChange(parseInt(id, 10));
          }}
          placeholder={selectLabel}
          initialSelectedId={String(selectedSortOption)}
          options={sortOptions.map(({ id, value }) => ({
            id: String(id),
            isSelectable: true,
            value,
          }))}
        />
      </div>
    </div>
  ) : null;
};

SortControlComponent.defaultProps = {
  onSortChange: () => {},
  selectedSortOption: 0,
  selectLabel: '',
  showSort: false,
  sortOptions: [],
};

export const SortControl = SortControlComponent;
