import * as React from 'react';

export interface ISearchResultsLayoutContext {
  isMobile: boolean;
}

export const SearchResultsLayoutContext = React.createContext<
  ISearchResultsLayoutContext
>({
  isMobile: false,
});
