import * as React from 'react';
import { ImageCover } from '../ImageCover';

import { classes } from './Thumbnail.st.css';
import { stripMarkTags } from '../stripMarkTags';

interface IThumbnailProps {
  onClick?(e: React.MouseEvent<HTMLElement>): void;
  title: string;
  url?: string;
  imageUrl?: string;
}

interface IDataHookAttribute {
  'data-hook'?: string;
}

export const Thumbnail: React.FunctionComponent<IThumbnailProps> = props => {
  const commonProps: React.HTMLAttributes<HTMLAnchorElement | HTMLSpanElement> &
    IDataHookAttribute = {
    title: stripMarkTags(props.title),
    onClick: props.onClick,
    tabIndex: -1,
    'aria-hidden': true,
    'data-hook': 'item-thumbnail',
  };

  const image = <ImageCover src={props.imageUrl} title={commonProps.title} />;

  if (props.url) {
    return (
      <a {...commonProps} className={classes.root} href={props.url}>
        {image}
      </a>
    );
  }

  return (
    <span {...commonProps} className={classes.root}>
      {image}
    </span>
  );
};
