import * as React from 'react';

import { classes } from './ViewAllButton.st.css';

interface IViewAllButtonProps {
  onClick?(e: React.MouseEvent<HTMLElement>): void;
  label: string;
  url: string;
  'data-hook'?: string;
}

export const ViewAllButton: React.FunctionComponent<IViewAllButtonProps> = props => {
  return (
    <a
      title={props.label}
      href={props.url}
      data-hook={props['data-hook'] || 'view-all-button'}
      onClick={props.onClick}
      className={classes.root}
      role="button"
    >
      {props.label}
    </a>
  );
};
