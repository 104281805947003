import * as React from 'react';
import { uniqueId } from 'lodash';

import { InputProps, Input } from 'wix-ui-core/dist/src/components/input';
import { InputWithOptions } from 'wix-ui-core/input-with-options';

import { ClearButton } from '../ClearButton';
import { SearchIcon } from '../SearchIcon';

import { st, classes } from './SearchBox.st.css';
import { ISearchBoxProps } from './SearchBox.types';
import { SearchResultsContext } from '../SearchResults/SearchResultsContext';

export class SearchBox extends React.Component<ISearchBoxProps> {
  inputRef: Input;
  baseId: string = uniqueId('SearchBox');

  static contextType = SearchResultsContext;
  context!: React.ContextType<typeof SearchResultsContext>;

  handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    this.props.onSubmit(e);
    this.inputRef.blur();
  };

  handleInputContainerClick = () => {
    this.inputRef.focus();
  };

  handleInputRef = (ref: Input) => {
    this.inputRef = ref;
  };

  getInputProps(): InputProps & { ref(ref: Input): void } {
    return {
      value: this.props.value,
      onChange: this.props.onChange,
      placeholder: this.props.placeholder,
      name: 'search',
      autoComplete: 'off',
      ref: this.handleInputRef,
      prefix: <SearchIcon className={classes.icon} data-hook="search-icon" />,
      suffix: !!this.props.value && (
        <ClearButton
          onClick={this.props.onClear}
          data-hook="clear-button"
          aria-label="{clearLabel}"
        />
      ),
    };
  }

  render() {
    return (
      <form
        className={st(classes.root, { mobileView: this.context.isMobile })}
        role="search"
        onSubmit={this.handleSubmit}
        data-hook="search-box-form"
      >
        <div
          onClick={this.handleInputContainerClick}
          data-hook="input-with-options-container"
          className={classes.inputContainer}
        >
          <InputWithOptions
            className={classes.input}
            inputProps={this.getInputProps()}
            data-hook="input-with-options"
            options={[]}
            id={`${this.baseId}-inputwithoptions`}
          />
        </div>
      </form>
    );
  }
}

export default SearchBox;
