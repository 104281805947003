import * as React from 'react';
import { isEqual } from 'lodash';

import { withTranslation, WithTranslation } from '@wix/wix-i18n-config';
import {
  InjectedExperimentsProps,
  withExperiments,
} from '@wix/wix-experiments-react';
// TODO: research why all sdk code is included in bundle.

import {
  ISearchDocument,
  SearchDocumentType,
  ISearchDocumentType,
} from '@wix/client-search-sdk';

import { GridLayout } from '../GridLayout';
import { ListLayout } from '../ListLayout';
import { SampleLayout } from '../SampleLayout';
import { EventList } from '../EventList';

import { SearchResults } from '../SearchResults';

import { ISearchSortProps } from '../SortControl';
import {
  getResultsFoundMessageKey,
  getResultsEmptyMessageKey,
} from './resultsMessage';
import { IWidgetProps, IWidgetState, ITab } from './Widget.types';
import { SearchRequestStatus } from '../../types/types';

import { ISearchSortConfig } from '../../platform/SearchResultsControllerStore.types';
import {
  buildSearchResultsUrl,
  toLocationSearchRequest,
} from '../../platform/location';
import {
  getDateFormatter,
  getTimeFormatter,
  getCurrencyFormatter,
} from '../../lib/formatters/get-formatters';

const DOCUMENT_TYPE_TRANSLATIONS: Record<SearchDocumentType, string> = {
  [SearchDocumentType.All]: 'searchResults.tabs.label.all',
  [SearchDocumentType.Pages]: 'searchResults.tabs.label.pages',
  [SearchDocumentType.Products]: 'searchResults.tabs.label.products',
  [SearchDocumentType.Blogs]: 'searchResults.tabs.label.blogs',
  [SearchDocumentType.Forums]: 'searchResults.tabs.label.forums',
  [SearchDocumentType.Bookings]: 'searchResults.tabs.label.bookings',
  [SearchDocumentType.Events]: 'searchResults.tabs.label.events',
};

const DOCUMENT_TYPE_TRANSLATIONS_WITH_COUNT: Record<
  SearchDocumentType,
  string
> = {
  [SearchDocumentType.All]: 'searchResults.tabs.label.all.withCount',
  [SearchDocumentType.Pages]: 'searchResults.tabs.label.pages.withCount',
  [SearchDocumentType.Products]: 'searchResults.tabs.label.products.withCount',
  [SearchDocumentType.Blogs]: 'searchResults.tabs.label.blogs.withCount',
  [SearchDocumentType.Forums]: 'searchResults.tabs.label.forums.withCount',
  [SearchDocumentType.Bookings]: 'searchResults.tabs.label.bookings.withCount',
  [SearchDocumentType.Events]: 'searchResults.tabs.label.events.withCount',
};

const DOCUMENT_TYPE_ACCESSIBILITY_LABEL: Record<SearchDocumentType, string> = {
  [SearchDocumentType.All]: 'searchResults.tabs.label.all.accessibilityLabel',
  [SearchDocumentType.Pages]:
    'searchResults.tabs.label.pages.accessibilityLabel',
  [SearchDocumentType.Products]:
    'searchResults.tabs.label.products.accessibilityLabel',
  [SearchDocumentType.Blogs]:
    'searchResults.tabs.label.blogs.accessibilityLabel',
  [SearchDocumentType.Forums]:
    'searchResults.tabs.label.forums.accessibilityLabel',
  [SearchDocumentType.Bookings]:
    'searchResults.tabs.label.bookings.accessibilityLabel',
  [SearchDocumentType.Events]:
    'searchResults.tabs.label.events.accessibilityLabel',
};

const demoTotals = {
  'public/blog/posts': 58,
  'public/site/pages': 73,
  'public/stores/products': 64,
};

function isAnchorElement(element: HTMLElement): element is HTMLAnchorElement {
  return element.nodeName === 'A';
}

// handle opening links in a new tab
// https://github.com/zeit/next.js/blob/42771f6451df6ba2dbda51e60968e9edcfde74af/packages/next/client/link.tsx#L149-L159
function shouldKeepDefaultBehaviour(e: React.MouseEvent<HTMLElement>): boolean {
  const currentTarget = e.currentTarget;
  return (
    isAnchorElement(currentTarget) &&
    ((currentTarget.target && currentTarget.target !== '_self') ||
      e.metaKey ||
      e.ctrlKey ||
      e.shiftKey ||
      (e.nativeEvent && e.nativeEvent.button === 1))
  );
}

export class WidgetComponent extends React.Component<
  WithTranslation & InjectedExperimentsProps & IWidgetProps,
  IWidgetState
> {
  state = {
    searchQuery: this.props.searchRequest.query,
  };

  listRef: React.RefObject<HTMLUListElement> = React.createRef();

  getTabTitleWithCount = (
    documentType: SearchDocumentType,
    count: number,
  ): string => {
    const titleOverride =
      this.props.settings.categoryList[documentType].useOverride &&
      this.props.settings.categoryList[documentType].override;

    if (titleOverride) {
      return `${titleOverride} (${count})`;
    }

    const titleI18NKey = DOCUMENT_TYPE_TRANSLATIONS_WITH_COUNT[documentType];
    return titleI18NKey
      ? this.props.t(titleI18NKey, {
          number: count,
        })
      : documentType;
  };

  getTabTitleWithoutCount = (documentType: SearchDocumentType): string => {
    const titleOverride =
      this.props.settings.categoryList[documentType].useOverride &&
      this.props.settings.categoryList[documentType].override;

    if (titleOverride) {
      return titleOverride;
    }

    const titleI18NKey = DOCUMENT_TYPE_TRANSLATIONS[documentType];
    return titleI18NKey ? this.props.t(titleI18NKey) : documentType;
  };

  getAccessibilityLabelForDocumentType = (
    documentType: SearchDocumentType,
    count: number,
  ): string => {
    const { t } = this.props;
    let title = documentType;

    const titleI18NKey = DOCUMENT_TYPE_ACCESSIBILITY_LABEL[documentType];

    if (titleI18NKey) {
      title = t(titleI18NKey, {
        number: count,
      });
    }

    return title;
  };

  createTab = ({ documentType }: ISearchDocumentType): ITab => {
    const { searchResponseTotals, isDemoContent } = this.props;

    const searchResponseTotalCounts = isDemoContent
      ? demoTotals
      : searchResponseTotals;

    const count =
      searchResponseTotalCounts && searchResponseTotalCounts[documentType];

    return {
      documentType,
      visible: this.props.settings.categoryList[documentType].visible,
      title: count
        ? this.getTabTitleWithCount(documentType, count)
        : this.getTabTitleWithoutCount(documentType),
      count: count || 0,
    };
  };

  componentDidUpdate(prevProps: IWidgetProps) {
    // TODO: review viewMode when resolved https://github.com/wix-private/native-components-infra/pull/28
    if (
      prevProps.searchRequest.query !== this.props.searchRequest.query ||
      prevProps.viewMode !== this.props.viewMode
    ) {
      this.setState({
        searchQuery: this.props.searchRequest.query,
      });
    }

    if (
      this.listRef.current &&
      !isEqual(prevProps.searchRequest, this.props.searchRequest)
    ) {
      // safari (as of 2019-11-18) ignores `preventScroll`:
      // https://bugs.webkit.org/show_bug.cgi?id=178583
      const safariCantPreventScrollX = window.scrollX;
      const safariCantPreventScrollY = window.scrollY;

      this.listRef.current.focus({
        preventScroll: true,
      });

      if (
        safariCantPreventScrollX !== window.scrollX ||
        safariCantPreventScrollY !== window.scrollY
      ) {
        window.scrollTo(safariCantPreventScrollX, safariCantPreventScrollY);
      }
    }
  }

  getTotalPages(pageSize: number, total: number | null): number {
    if (total) {
      return Math.floor(total / pageSize) + (total % pageSize === 0 ? 0 : 1);
    }
    return 0;
  }

  getTotalResultsText(totalCount: number) {
    const { searchRequest, settings, isDemoContent, t } = this.props;

    const resultsMessageKey =
      totalCount > 0
        ? getResultsFoundMessageKey({
            isWithNumber: settings.isResultsMessageWithNumber,
            isWithQuery: settings.isResultsMessageWithQuery,
          })
        : getResultsEmptyMessageKey({
            isWithNumber: settings.isResultsEmptyMessageWithNumber,
            isWithQuery: settings.isResultsEmptyMessageWithQuery,
          });

    const searchQuery =
      isDemoContent && !searchRequest.query
        ? t('resultsFoundMessage.demoQuery')
        : searchRequest.query;

    return t(resultsMessageKey, {
      number: totalCount,
      query: searchQuery,
    });
  }

  handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchQuery: e.target.value });
  };

  handleQueryClear = () => {
    this.setState({ searchQuery: '' }, () => {
      this.handleSubmit();
    });
  };

  handlePageChange = (v: {
    event: React.MouseEvent<HTMLElement>;
    page: number;
  }) => {
    const { event, page } = v;

    if (shouldKeepDefaultBehaviour(event)) {
      event.stopPropagation();
      return;
    }

    const { onPageChange } = this.props;

    onPageChange(page);
  };

  handleTabChange = (
    tabs: Array<ITab & { title: string }>,
    tabIndex: number,
  ) => {
    const { onDocumentTypeChange } = this.props;
    onDocumentTypeChange(tabs[tabIndex].documentType, {
      source: 'tabs',
    });
  };

  handleSamplesViewAllClick = (
    e: React.MouseEvent<HTMLElement>,
    documentType: SearchDocumentType,
  ) => {
    if (shouldKeepDefaultBehaviour(e)) {
      e.stopPropagation();
      return;
    }
    e.preventDefault();

    const { onDocumentTypeChange } = this.props;

    onDocumentTypeChange(documentType, {
      source: 'samples',
    });
  };

  handleSubmit = () => {
    const { onQuerySubmit } = this.props;
    const { searchQuery } = this.state;

    onQuerySubmit(searchQuery);
  };

  handleSearchResultClick = (
    e: React.MouseEvent<HTMLElement>,
    item: ISearchDocument,
    index: number,
  ) => {
    if (shouldKeepDefaultBehaviour(e)) {
      e.stopPropagation();
      return;
    }
    e.preventDefault();

    this.props.onDocumentClick(item, index);
  };

  buildPageUrl = (page: number): string => {
    const locationRequest = {
      ...toLocationSearchRequest(this.props.searchRequest),
      page,
    };
    return buildSearchResultsUrl(
      this.props.searchResultsAbsoluteUrl,
      locationRequest,
    );
  };

  getSortProps = (sortConfig: ISearchSortConfig): ISearchSortProps => {
    const { t } = this.props;
    return {
      ...sortConfig,
      sortOptions: sortConfig?.sortOptions.map(sortOption => ({
        ...sortOption,
        value: t(sortOption.valueKey),
      })),
      selectLabel: t('searchResults.sort.dropdown.label'),
    };
  };

  buildDocuments = documents => {
    const { isDemoContent, t } = this.props;

    return isDemoContent
      ? documents.map(document => {
          return Object.entries(document).reduce(
            (acc, [key, value]: [string, any]) => {
              acc[key] =
                typeof value === 'string' &&
                value.startsWith('mockedSearchResults.')
                  ? t(value)
                  : value;
              return acc;
            },
            {},
          );
        })
      : documents;
  };

  buildItems = items => {
    const { isDemoContent } = this.props;

    return isDemoContent
      ? items.map(item => ({
          ...item,
          documents: this.buildDocuments(item.documents),
        }))
      : items;
  };

  render() {
    const {
      settings,
      documentTypes,
      searchRequest,
      searchRequestStatus,
      searchResponse,
      searchSamples,
      isDemoContent,
      isMobile,
      t,
      searchResponseTotals,
      locale,
      reportError,
    } = this.props;

    const { searchQuery } = this.state;

    const totalPages = this.getTotalPages(
      searchRequest.paging.pageSize,
      searchResponse.totalResults,
    );

    // TODO: resolve Alignment vs TabAlignment
    const resultsMenuAlignment = settings.resultsMenuAlignment as any;
    const paginationAlignment = settings.paginationAlignment;
    const categoryList = settings.categoryList;

    // TODO: remove when the widget starts to use SSR-rendered screen instead of loading indicator
    if (!categoryList) {
      return <div />;
    }

    const visibleTabsWithTitles = documentTypes
      .map(this.createTab)
      .filter(tab => categoryList[tab.documentType].visible)
      .sort(
        (a, b) =>
          categoryList[a.documentType].index -
          categoryList[b.documentType].index,
      );

    const totalCount = visibleTabsWithTitles
      .filter(tab => tab.documentType !== SearchDocumentType.All)
      .reduce((total, tab) => {
        return total + tab.count;
      }, 0);

    const allTab = visibleTabsWithTitles.find(
      tab => tab.documentType === SearchDocumentType.All,
    );

    if (allTab?.visible) {
      allTab.title =
        totalCount > 0
          ? this.getTabTitleWithCount(SearchDocumentType.All, totalCount)
          : this.getTabTitleWithoutCount(SearchDocumentType.All);
    }

    const activeTabIndex = visibleTabsWithTitles.findIndex(
      tab => tab.documentType === searchRequest.documentType,
    );

    const shouldShowSamples =
      searchRequest.documentType === SearchDocumentType.All;

    const samplesWithTitles = shouldShowSamples
      ? searchSamples
          .map(s => ({
            ...s,
            accessibilityLabel: searchResponseTotals
              ? this.getAccessibilityLabelForDocumentType(
                  s.documentType,
                  searchResponseTotals[s.documentType] || 0,
                )
              : this.getTabTitleWithoutCount(s.documentType),
            title: searchResponseTotals
              ? this.getTabTitleWithCount(
                  s.documentType,
                  searchResponseTotals[s.documentType] || 0,
                )
              : this.getTabTitleWithoutCount(s.documentType),
          }))
          .filter(sample => categoryList[sample.documentType].visible)
      : [];

    const totalResultsText = this.getTotalResultsText(totalCount);

    const noResultsText = getResultsEmptyMessageKey({
      isWithNumber: settings.isResultsEmptyMessageWithNumber,
      isWithQuery: settings.isResultsEmptyMessageWithQuery,
    });

    function handleException(ex) {
      reportError(ex);
      console.error(ex);
    }

    const listProps = {
      'aria-label': totalResultsText,
      formatCurrency: getCurrencyFormatter(locale, handleException),
      formatDate: getDateFormatter(locale, handleException),
      formatTime: getTimeFormatter(locale, handleException),
      items: this.buildDocuments(searchResponse.documents),
      listRef: this.listRef,
      onItemLinkClick: this.handleSearchResultClick,
      t,
    };

    const getListLayout = () => <ListLayout {...listProps} />;

    const failed = searchRequestStatus === SearchRequestStatus.Failed;
    return (
      <SearchResults
        searchQuery={searchQuery}
        searchPlaceholder={settings.searchBarPlaceholder}
        searchClearLabel={t('searchResults.clearLabel')}
        isSearchBarEnabled={settings.isSearchBarEnabled}
        tabs={failed ? [] : visibleTabsWithTitles}
        totalCount={totalCount}
        getTotalResultsText={(count: number) => this.getTotalResultsText(count)}
        noResultsText={t(noResultsText, {
          query: this.props.searchRequest.query,
        })}
        tabsAlignment={resultsMenuAlignment}
        activeTabIndex={activeTabIndex}
        onTabChange={(tabIndex: number) =>
          this.handleTabChange(visibleTabsWithTitles, tabIndex)
        }
        onQueryChange={this.handleQueryChange}
        onQueryClear={this.handleQueryClear}
        onSubmit={this.handleSubmit}
        isPaginationHidden={shouldShowSamples}
        currentPage={searchRequest.paging.page}
        totalPages={totalPages > 1 ? totalPages : null}
        paginationAlignment={paginationAlignment}
        onPageChange={this.handlePageChange}
        isLoading={searchRequestStatus === SearchRequestStatus.Loading}
        isMobile={isMobile}
        isDemoContent={isDemoContent}
        demoContentNotificationText={t(
          'searchResults.demoContentNotificationText',
        )}
        buildPageUrl={this.buildPageUrl}
        sortProps={this.getSortProps(this.props.sortConfig)}
      >
        {failed && (
          <div data-hook="search-error-message">
            {t('resultsEmptyMessage.requestFailed')}
          </div>
        )}
        {(() => {
          switch (searchRequest.documentType) {
            case SearchDocumentType.All:
              return shouldShowSamples ? (
                <SampleLayout
                  {...listProps}
                  results={this.buildItems(samplesWithTitles)}
                  onViewAllClick={this.handleSamplesViewAllClick}
                  viewAllLabel={t('searchResults.samples.label.viewAll')}
                />
              ) : (
                getListLayout()
              );
            case SearchDocumentType.Products:
              return <GridLayout {...listProps} />;
            case SearchDocumentType.Events:
              return <EventList {...listProps} />;

            default:
              return getListLayout();
          }
        })()}
      </SearchResults>
    );
  }
}

export const Widget = withTranslation()(withExperiments(WidgetComponent));
