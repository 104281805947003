import * as React from 'react';
import { TPAComponentsProvider } from 'wix-ui-tpa/TPAComponentsConfig';
import { Tabs, ALIGNMENT } from 'wix-ui-tpa/Tabs';
import { Pagination } from 'wix-ui-tpa/Pagination';
import { SearchResultsLayoutContext } from '../SearchResultsLayoutContext';
import { ISearchResultsProps } from './SearchResults.types';
import { SearchResultsContext } from './SearchResultsContext';

import { SearchInput } from '../SearchInput';
import { ResultsStatus } from '../ResultsStatus';
import { Spinner } from '../Spinner';
import { DemoContentNotification } from '../DemoContentNotification';

import { st, classes } from './SearchResults.st.css';
import { SortControl } from '../SortControl/SortControl';

const MAX_PAGES_TO_SHOW_IN_PAGINATION = {
  DESKTOP: 5,
  MOBILE: 3,
};

const TabLine = ({
  activeTabIndex,
  alignment,
  isLoading,
  items,
  noResultsText,
  onTabClick,
}) => {
  const shouldDisplayStatusText =
    !isLoading && items?.[activeTabIndex]?.count === 0;
  return (
    <>
      <Tabs
        alignment={alignment}
        items={items}
        activeTabIndex={activeTabIndex}
        onTabClick={onTabClick}
        data-hook="tabs"
      />
      {shouldDisplayStatusText && <ResultsStatus text={noResultsText} />}
    </>
  );
};

const StatusLine = ({
  count,
  getTotalResultsText,
  isLoading,
  noResultsText,
}) => {
  if (isLoading) {
    return null;
  }
  return count === 0 ? (
    <ResultsStatus text={noResultsText} />
  ) : (
    <ResultsStatus text={getTotalResultsText(count)} />
  );
};

const SearchResults: React.FunctionComponent<ISearchResultsProps> = ({
  isDemoContent = false,
  tabsAlignment = ALIGNMENT.left,
  isLoading = false,
  isMobile,
  isSearchBarEnabled,
  isPaginationHidden,
  buildPageUrl,
  totalCount,
  getTotalResultsText,
  noResultsText,
  sortProps,
  ...props
}) => (
  <div
    data-hook="search-results-root"
    className={st(classes.root, { mobileView: isMobile })}
  >
    <TPAComponentsProvider value={{ mobile: isMobile }}>
      <SearchResultsContext.Provider value={{ isMobile }}>
        {isSearchBarEnabled && (
          <SearchInput
            value={props.searchQuery}
            placeholder={props.searchPlaceholder}
            clearLabel={props.searchClearLabel}
            onChange={props.onQueryChange}
            onClear={props.onQueryClear}
            onSubmit={props.onSubmit}
          />
        )}
        <div data-hook="content-wrapper" className={classes.contentWrapper}>
          <div
            data-hook="content"
            className={st(classes.content, { loading: isLoading })}
          >
            {props.tabs.length > 1 ? (
              <TabLine
                activeTabIndex={props.activeTabIndex}
                alignment={tabsAlignment}
                isLoading={isLoading}
                items={props.tabs}
                noResultsText={noResultsText}
                onTabClick={props.onTabChange}
              />
            ) : (
              <StatusLine
                count={totalCount}
                getTotalResultsText={getTotalResultsText}
                isLoading={isLoading}
                noResultsText={noResultsText}
              />
            )}

            <SortControl {...sortProps} />

            {isDemoContent ? (
              <DemoContentNotification
                text={props.demoContentNotificationText}
              />
            ) : null}

            <SearchResultsLayoutContext.Provider
              value={{
                isMobile,
              }}
            >
              {props.children}
            </SearchResultsLayoutContext.Provider>

            {props.currentPage && props.totalPages && !isPaginationHidden && (
              <div
                className={st(classes.paginationWrapper, {
                  alignment: props.paginationAlignment,
                })}
              >
                <Pagination
                  totalPages={props.totalPages}
                  currentPage={props.currentPage}
                  maxPagesToShow={
                    isMobile
                      ? MAX_PAGES_TO_SHOW_IN_PAGINATION.MOBILE
                      : MAX_PAGES_TO_SHOW_IN_PAGINATION.DESKTOP
                  }
                  pageUrl={buildPageUrl}
                  onChange={props.onPageChange}
                  data-hook="pagination"
                />
              </div>
            )}
          </div>
          {isLoading && <Spinner dataHook="spinner" />}
        </div>
      </SearchResultsContext.Provider>
    </TPAComponentsProvider>
  </div>
);

export { SearchResults };
