import * as React from 'react';

import { IListLayoutProps, IListLayoutItemProps } from '../Layout.types';
import { SearchResultsLayoutContext } from '../SearchResultsLayoutContext';
import { ListLayoutItem } from './ListLayoutItem';

import { st, classes } from './ListLayout.st.css';

export class ListLayout extends React.Component<IListLayoutProps> {
  static contextType = SearchResultsLayoutContext;
  context!: React.ContextType<typeof SearchResultsLayoutContext>;

  static defaultProps: Partial<IListLayoutProps> = {
    renderItem: (props: IListLayoutItemProps, key) => {
      return <ListLayoutItem key={key} {...props} />;
    },
  };

  render() {
    const { renderItem, ...formatterProps } = this.props;
    return (
      <ul
        className={st(classes.root, { mobileView: this.context.isMobile })}
        data-hook="list-layout"
        ref={this.props.listRef}
        tabIndex={-1}
        role="region list"
        aria-label={this.props['aria-label']}
      >
        {this.props.items.map((item, index) => {
          const handleClick = (e: React.MouseEvent<HTMLElement>) =>
            this.props.onItemClick && this.props.onItemClick(e, item, index);

          const handleLinkClick = (e: React.MouseEvent<HTMLElement>) =>
            this.props.onItemLinkClick &&
            this.props.onItemLinkClick(e, item, index);

          const listItemProps: IListLayoutItemProps = {
            ...formatterProps,
            item,
            onClick: handleClick,
            onLinkClick: handleLinkClick,
          };

          return renderItem ? renderItem(listItemProps, index) : null;
        })}
      </ul>
    );
  }
}
