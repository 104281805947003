import * as React from 'react';

import { Thumbnail } from '../Thumbnail';

import { ISearchEventDocument, EventType } from '@wix/client-search-sdk';

import { IListLayoutItemProps } from '../Layout.types';
import { Title } from '../Title';
import { SearchResultsLayoutContext } from '../SearchResultsLayoutContext';

import { classes as descriptionClasses } from '../Description/Description.st.css';
import { st, classes } from './EventListItem.st.css';

import { getWixImageUrl } from '../getWixImageUrl';

const THUMBNAIL_WIDTH = 176;
const THUMBNAIL_HEIGHT = 124;

export const enum EventListItemRenderMode {
  default = 'default',
  condensed = 'condensed',
}

interface IEventListItemProps extends IListLayoutItemProps {
  renderMode?: EventListItemRenderMode;
}

export class EventListItem extends React.Component<IEventListItemProps> {
  static contextType = SearchResultsLayoutContext;
  context!: React.ContextType<typeof SearchResultsLayoutContext>;

  static defaultProps = {
    renderMode: EventListItemRenderMode.default,
  };

  render() {
    const { renderMode, formatCurrency, t, item } = this.props;

    const renderTime = (
      { startDate }: ISearchEventDocument,
      formatDate,
      formatTime,
    ) => {
      if (!startDate) {
        return null;
      }

      return (
        <div data-hook="event-start" className={classes.eventDateTime}>
          <span data-hook="event-start-date">{formatDate(startDate)}</span>
          <span>&nbsp;|&nbsp;</span>
          <span data-hook="event-start-time">{formatTime(startDate)}</span>
        </div>
      );
    };

    const getTranslatedEventTypeLabel = (
      { eventType, minPrice, maxPrice, currency }: ISearchEventDocument,
      formatCurrency,
      t,
    ) => {
      const translation = {
        [EventType.paid]: () => {
          const translationKey =
            minPrice !== maxPrice
              ? 'searchResults.list.events.eventTypeLabel.paid'
              : 'searchResults.list.events.eventTypeLabel.paid.singlePrice';
          return t(translationKey, {
            minPrice: formatCurrency(minPrice, currency),
            maxPrice: formatCurrency(maxPrice, currency),
          });
        },
      }[eventType];
      return translation ? translation() : undefined;
    };

    const getEventType = (event: ISearchEventDocument) =>
      getTranslatedEventTypeLabel(event, formatCurrency, t);

    const renderEventType = (event: ISearchEventDocument) => {
      const translation = getEventType(event);
      if (!translation) {
        return null;
      }
      return (
        <div className={classes.eventType} data-hook="event-type">
          {translation}
        </div>
      );
    };

    const renderDescription = ({
      item,
      formatDate,
      formatTime,
    }: Pick<IListLayoutItemProps, 'item' | 'formatDate' | 'formatTime'>) => {
      const event = item as ISearchEventDocument;
      return (
        <div className={descriptionClasses.root}>
          {renderTime(event, formatDate, formatTime)}
          <div
            className={classes.eventLocation}
            data-hook="event-location"
            dangerouslySetInnerHTML={{ __html: event.location }}
          />
          {renderMode === EventListItemRenderMode.default &&
            renderEventType(event)}
        </div>
      );
    };

    const renderTitle = ({
      item: titleItem,
      onLinkClick,
    }: Pick<IListLayoutItemProps, 'item' | 'onLinkClick'>) => {
      const title = (
        <Title
          title={titleItem.title}
          onClick={onLinkClick}
          url={titleItem.url}
        />
      );
      const eventType = getEventType(item as ISearchEventDocument);
      return renderMode === EventListItemRenderMode.default ? (
        title
      ) : (
        <div>
          <div className={classes.condensedTitle}>
            <span
              className={st(classes.condensedTitleStrong, {
                narrow: !!eventType,
              })}
            >
              {title}
            </span>
            <div
              className={st(classes.condensedTitleEvent)}
              data-hook="event-type"
            >
              {eventType}
            </div>
          </div>
        </div>
      );
    };

    const {
      thumbnailHeight = THUMBNAIL_HEIGHT,
      thumbnailWidth = THUMBNAIL_WIDTH,
    } = this.props;

    let imageUrl: string;

    if (
      item.image &&
      'name' in item.image &&
      item.image.name.startsWith('http')
    ) {
      imageUrl = item.image.name;
    } else {
      imageUrl =
        getWixImageUrl(item.image, thumbnailWidth, thumbnailHeight) || '';
    }

    return (
      <li
        data-hook="list-layout-item"
        role="listitem"
        className={st(classes.root, { mobileView: this.context.isMobile })}
        onClick={this.props.onClick}
      >
        {!this.context.isMobile && (
          <Thumbnail
            title={item.title}
            onClick={this.props.onClick}
            url={item.url}
            imageUrl={imageUrl}
          />
        )}
        <div className={classes.content}>
          {renderTitle({
            item: this.props.item,
            onLinkClick: this.props.onClick,
          })}
          {renderDescription({
            item: this.props.item,
            formatTime: this.props.formatTime,
            formatDate: this.props.formatDate,
          })}
        </div>
      </li>
    );
  }
}
