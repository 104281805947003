import * as React from 'react';
import { ImageLoader, ImageStatus } from '../ImageLoader';
import { st, classes } from './ImageCover.st.css';

interface IImageCoverProps {
  src?: string;
  title?: string;
}

export const ImageCover: React.FunctionComponent<IImageCoverProps> = ({
  src,
  title,
}) => {
  return (
    <ImageLoader src={src}>
      {({ status }) => (
        <div
          className={st(classes.root, {
            loading: status === ImageStatus.LOADING,
            placeholder: status === ImageStatus.ERROR,
          })}
          {...(status === ImageStatus.LOADING ? { 'data-src': src } : {})}
          data-hook="image-cover"
          title={title}
          style={{
            backgroundImage:
              status === ImageStatus.LOADED ? `url(${src})` : undefined,
          }}
        />
      )}
    </ImageLoader>
  );
};
