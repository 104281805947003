import * as React from 'react';
import { ISampleLayoutProps, IListLayoutItemProps } from '../Layout.types';

import { SearchDocumentType } from '@wix/client-search-sdk';

import { Title } from '../Title';
import { ILayoutItem } from '../LayoutItem.types';
import { SearchResultsLayoutContext } from '../SearchResultsLayoutContext';

import { ListLayoutItem } from '../ListLayout';
import { GridLayoutItem } from '../GridLayout';
import { ViewAllButton } from '../ViewAllButton';

import { st, classes } from './SampleLayout.st.css';
import {
  EventListItem,
  EventListItemRenderMode,
} from '../EventList/EventListItem';

const THUMBNAIL_WIDTH = {
  DESKTOP: 102,
  MOBILE: 102,
};

const getThumbnailWidth = isMobile =>
  isMobile ? THUMBNAIL_WIDTH.MOBILE : THUMBNAIL_WIDTH.DESKTOP;

type ISample = ISampleLayoutProps['results'][0];
const enum LayoutType {
  grid = 'grid',
  list = 'list',
  events = 'events',
}

export class SampleLayout extends React.Component<ISampleLayoutProps> {
  static contextType = SearchResultsLayoutContext;
  context!: React.ContextType<typeof SearchResultsLayoutContext>;

  static typeLayoutMap: Record<SearchDocumentType, LayoutType | null> = {
    [SearchDocumentType.All]: null,
    [SearchDocumentType.Pages]: LayoutType.list,
    [SearchDocumentType.Products]: LayoutType.grid,
    [SearchDocumentType.Blogs]: LayoutType.list,
    [SearchDocumentType.Forums]: LayoutType.list,
    [SearchDocumentType.Bookings]: LayoutType.list,
    [SearchDocumentType.Events]: LayoutType.events,
  };

  getRenderItemProps = (
    item: ILayoutItem,
    index: number,
  ): IListLayoutItemProps & { key: any } => {
    const onClick = (e: React.MouseEvent<HTMLElement>) =>
      this.props.onItemClick && this.props.onItemClick(e, item, index);

    const onLinkClick = (e: React.MouseEvent<HTMLElement>) =>
      this.props.onItemLinkClick && this.props.onItemLinkClick(e, item, index);

    const { formatCurrency, formatDate, formatTime, t } = this.props;

    return {
      onClick,
      onLinkClick,
      item,
      key: index,
      formatCurrency,
      formatDate,
      formatTime,
      t,
    };
  };

  renderListLayoutItem = (item: ILayoutItem, index: number) => {
    return (
      <ListLayoutItem
        {...this.getRenderItemProps(item, index)}
        thumbnailWidth={getThumbnailWidth(this.context.isMobile)}
      />
    );
  };

  renderGridLayoutItem = (item: ILayoutItem, index: number) => {
    return <GridLayoutItem {...this.getRenderItemProps(item, index)} />;
  };

  renderEventsLayoutItem = (item: ILayoutItem, index: number) => {
    return (
      <EventListItem
        {...this.getRenderItemProps(item, index)}
        thumbnailWidth={getThumbnailWidth(this.context.isMobile)}
        renderMode={EventListItemRenderMode.condensed}
      />
    );
  };

  renderSampleGroup = (sample: ISample) => {
    const { onViewAllClick, viewAllLabel } = this.props;

    if (!sample.documents.length) {
      return;
    }

    const layoutType =
      SampleLayout.typeLayoutMap[sample.documentType] || LayoutType.list;
    const renderItem = {
      grid: this.renderGridLayoutItem,
      list: this.renderListLayoutItem,
      events: this.renderEventsLayoutItem,
    }[layoutType];

    return (
      <div
        data-hook="sample-layout-group"
        className={classes.group}
        key={sample.documentType}
        aria-label={sample.accessibilityLabel}
      >
        <Title
          data-hook="sample-layout-group-title"
          role="heading"
          title={sample.title}
          onClick={e => onViewAllClick(e, sample.documentType)}
          url={sample.url}
        />

        <ul
          data-hook="sample-layout-group-items"
          className={st(classes.groupItems, { layout: layoutType })}
        >
          {sample.documents.map(renderItem)}
        </ul>
        <ViewAllButton
          label={viewAllLabel}
          url={sample.url}
          onClick={e => onViewAllClick(e, sample.documentType)}
          data-hook="sample-layout-group-view-all"
        />
      </div>
    );
  };

  render() {
    const isMobile = this.context.isMobile;

    return (
      <ul
        className={st(classes.root, { mobileView: isMobile })}
        data-hook="sample-layout"
        ref={this.props.listRef}
        tabIndex={-1}
        role="region list"
        aria-label={this.props['aria-label']}
      >
        {this.props.results.map(this.renderSampleGroup)}
      </ul>
    );
  }
}
