import * as React from 'react';

import { st, classes } from './Description.st.css';
import { SearchResultsLayoutContext } from '../SearchResultsLayoutContext';

interface IDescriptionProps {
  description: string;
}

export class Description extends React.Component<IDescriptionProps> {
  static contextType = SearchResultsLayoutContext;
  context!: React.ContextType<typeof SearchResultsLayoutContext>;

  render() {
    if (!this.props.description) {
      return null;
    }

    return (
      <p
        className={st(classes.root, { mobileView: this.context.isMobile })}
        data-hook="item-description"
        dangerouslySetInnerHTML={{ __html: this.props.description }}
      />
    );
  }
}
